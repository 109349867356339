import { Box, Button, Typography } from "@mui/material";
import { CurrentUserSelector, setCurrentUser as setCurrentUserSlice } from "app/slices/UserSlice";
import FunctionIcon from "assets/icons/function.svg";
import GridIcon from "assets/icons/grid.svg";
import InfoIcon from "assets/icons/info.svg";
import { useCoPilotQueryCount } from "hooks/CoPilots";
import { useUserAssignedClusters } from "hooks/VSortsClusterData";
import { useGetSubscriptionDetails } from "hooks/subscriptionData";
import { getUserProfile } from "hooks/userData";
import { USER_GROUP, USER_STATUS } from "mock/dashboard";
import { DynamicModel } from "pages/adminV2/Components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Cluster, UserClusterStatus, ViewType } from "types/dashboard";
import { ClusterUser, User } from "types/user";
import ClusterFilter from "./ClusterFilter";
import Clusters from "./Clusters";
import CoPilotList from "./CoPilots/CoPilotList";
import PaymentStatusModal from "./PaymentStatusModal";
import TopNavCard from "./TopNavCard";
import UserProfile from "./UserProfile";
import styles from "./styles";

const Dashboard: React.FC = () => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [coPilotQueryCount, setCoPilotQueryCount] = useState<User | null>(null);
    const user = useSelector(CurrentUserSelector);
    const [viewType, setViewType] = useState<ViewType>(ViewType.VIEW_MODE_LIST);
    const [clusters, setClusters] = useState<Cluster[]>([]);
    const [selectedCluster, setSelectedCluster] = useState<Cluster | null>(null);
    const [selectedClusterUser, setSelectedClusterUser] = useState<ClusterUser | null>(null);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [clusterStatus, setClusterStatus] = useState<UserClusterStatus>({
        activeClusters: 0,
        completedClusters: 0,
        coPilotsQueries: "TBD",
    });
    const [selectedTab, setSelectedTab] = useState(0);
    const [filteredClusters, setFilteredClusters] = useState<Cluster[]>([]);
    const [currentFilter, setCurrentFilter] = useState("all");
    const [currentSorting, setCurrentSorting] = useState("Open");
    const [openPaymentModal, setOpenPaymentModal] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const stripeRedirect = searchParams.get("stripeRedirect") || false;
    const [paymentDetails, setPaymentDetails] = useState({});

    const dispatch = useDispatch();

    console.log(stripeRedirect);
    // useEffect(() => {
    //     if(stripeRedirect){
    //         setOpenPaymentModal(true)
    //     }
    // }, [stripeRedirect])

    const {
        isLoading,
        isError,
        data: paymentDetailsData,
        error: isSubscriptionDetailsError,
        refetch,
    } = useGetSubscriptionDetails({
        onSuccess: (data: any) => {
            console.log(data);
            setPaymentDetails(data);
            setOpenPaymentModal(true);
        },
        onError: (error: any) => {
            console.log(error);
        },
        isEnabled: stripeRedirect === "true",
    });

    const {
        isLoading: isAllVSortsClusterDataLoading,
        isError: isAllVSortsClusterDataError,
        data: allClusters,
        error,
        refetch: refetchAllVSortsContainerData,
    } = useUserAssignedClusters({});

    if (allClusters) {
        if (allClusters.length && !clusters.length) {
            let activeClusterCount = 0;
            let completedClusterCount = 0;
            setClusters(allClusters);
            setSelectedCluster(allClusters[0]);
            allClusters?.map((eachCluster) => {
                if (eachCluster?.status == "progress" || eachCluster?.status == "not started") {
                    activeClusterCount = activeClusterCount + 1;
                }
                if (eachCluster?.status == "submitted") {
                    completedClusterCount = completedClusterCount + 1;
                }
            });

            setClusterStatus({
                ...clusterStatus,
                activeClusters: activeClusterCount,
                completedClusters: completedClusterCount,
            });
        }
    }

    useEffect(() => {
        let clusterList = clusters ?? [];

        if (currentFilter === "opentasks") {
            clusterList = clusterList.filter(
                (cluster: any) => cluster.status === "progress" || cluster.status === "not started"
            );
        } else if (currentFilter === "completedtasks") {
            clusterList = clusterList.filter((cluster: any) => cluster.status === "submitted");
        }

        clusterList = [...clusterList];

        if (currentSorting) {
            clusterList.sort((cluster: any, nextCluster: any) => {
                const dateCluster = new Date(cluster.last_modified);
                const dateNextCluster = new Date(nextCluster.last_modified);

                return currentSorting === "newestcluster"
                    ? dateNextCluster.getTime() - dateCluster.getTime()
                    : dateCluster.getTime() - dateNextCluster.getTime();
            });
        }

        setFilteredClusters(clusterList);
    }, [currentSorting, currentFilter, clusters]);

    const onUserDataFetchSuccess = (data: any) => {
        const currentUser : User = {
            id: (data?.user_id as string) || "",
            name: (data?.name as string) || "",
            nickname: (data?.nickname as string) || "",
            img: data?.picture || user?.picture || "",
            email: (data?.email as string) || (user?.email as string) || "",
            first_name: (data?.first_name as string) || "",
            last_name: (data?.last_name as string) || "",
            organizationId: (data?.organization?.id as number),
            organizationName: (data?.organization?.domain_name as string) || "",
            //role: getUserRole(data?.roles as string[]),
            role: data?.role,
            roles: data?.roles,
            permissions: data?.permissions,
            organization: data?.organization,
            subscriptionInfo: data?.subscriptionInfo
        }
        setCurrentUser(currentUser);
        // set current user to redux
        dispatch(setCurrentUserSlice(currentUser))
    };

    const onCoPilotQueryCountSuccess = (data: any) => {
        setClusterStatus({
            ...clusterStatus,
            coPilotsQueries: data["count"],
        });
    };

    const onUserDataFetchError = (error: any) => {
        setCurrentUser(null);
    };

    const onCoPilotQueryCountError = (error: any) => {
        setClusterStatus({
            ...clusterStatus,
            coPilotsQueries: "",
        });
    };

    const {
        isFetching: isProfileDataFetching,
        isLoading: isProfileDataLoading,
        isError: isProfileDataError,
        data: profileData,
        error: profileDataError,
        refetch: refetchUserProfile,
    } = getUserProfile({
        onSuccess: onUserDataFetchSuccess,
        onError: onUserDataFetchError,
    });

    const {
        isFetching: isQueryCountDataFetching,
        isLoading: isQueryCountDataLoading,
        isError: isQueryCountDataError,
        data: queryCountData,
        error: queryCountDataError,
        refetch: refetchQueryCount,
    } = useCoPilotQueryCount({
        onSuccess: onCoPilotQueryCountSuccess,
        onError: onCoPilotQueryCountError,
    });

    const onSwitchMode = (type: ViewType) => {
        setViewType(type);
    };

    const onSelectCluster = (cluster: Cluster) => {
        setSelectedCluster(cluster);
    };

    const onSelectClusterUser = (user: ClusterUser) => {
        setSelectedClusterUser(user);
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleContainer}>
                <Typography sx={styles.title}>Dashboard</Typography>
                <Box sx={styles.flexContainer}>
                    <Button sx={styles.btnAction} onClick={() => onSwitchMode(ViewType.VIEW_MODE_LIST)}>
                        <img src={FunctionIcon} alt="#" style={styles.actionIcon} />
                    </Button>
                    <Button sx={styles.btnAction} onClick={() => onSwitchMode(ViewType.VIEW_MODE_GRID)}>
                        <img src={GridIcon} alt="#" style={styles.actionIcon} />
                    </Button>
                </Box>
            </Box>
            <Box sx={styles.contentContainer}>
                <Box sx={styles.mainSection}>
                    <Box sx={styles.contentContainer}>
                        <Box sx={styles.profileContainer}>
                            <Box sx={styles.profile}>
                                <UserProfile
                                    user={currentUser}
                                    status={USER_STATUS}
                                    organization={currentUser?.organizationName}
                                    group={USER_GROUP}
                                    viewType={viewType}
                                    clusterStatus={clusterStatus}
                                />
                                {viewType === ViewType.VIEW_MODE_GRID && (
                                    <Box sx={{ ...styles.userValues, ...styles.dashboardStatus }}>
                                        <Box sx={{ ...styles.status, ...styles.dashboardStatusCard }}>
                                            {/* <img src={VignettesIcon} alt="i" style={styles.statusIcon} /> */}
                                            <Box sx={styles.statusHeader}>
                                                <Typography sx={styles.statusTitle}>
                                                    Flagged
                                                    <br /> Vignettes
                                                </Typography>
                                                <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                                            </Box>
                                            <Typography sx={styles.statusValue}>
                                                {USER_STATUS.flaggedVignettes}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ...styles.status, ...styles.dashboardStatusCard }}>
                                            {/* <img src={UnpackedIcon} alt="i" style={styles.statusIcon} /> */}
                                            <Box sx={styles.statusHeader}>
                                                <Typography sx={styles.statusTitle}>
                                                    Unpacked
                                                    <br /> Vignettes
                                                </Typography>
                                                <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                                            </Box>
                                            <Typography sx={styles.statusValue}>
                                                {USER_STATUS.unpackedVignettes}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                ...styles.status,
                                                ...styles.statusGreen,
                                                ...styles.dashboardStatusCard,
                                            }}
                                        >
                                            {/* <img src={CulturalIcon} alt="i" style={styles.statusIcon} /> */}
                                            <Box sx={styles.statusTitle}>
                                                <Typography sx={{ ...styles.statusTitle, ...styles.colorGreen }}>
                                                    Cultural
                                                    <br /> Profiency
                                                </Typography>
                                                <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                                            </Box>
                                            <Typography sx={{ ...styles.statusValue, ...styles.colorGreen }}>
                                                {USER_STATUS.culturalProfiency}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            {/* <Box sx={styles.dasboardReport}></Box> */}
                        </Box>
                        <Box sx={styles.clusters}>
                            <Box sx={styles.flexContainer}>
                                <TopNavCard
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    setOpenFilter={setOpenFilter}
                                />
                            </Box>
                            {selectedTab == 0 && (
                                <Box sx={styles.flex}>
                                    <Clusters
                                        currentUser={currentUser}
                                        isLoading={isAllVSortsClusterDataLoading}
                                        clusters={filteredClusters}
                                        onSelectCluster={onSelectCluster}
                                        setOpenFilter={setOpenFilter}
                                    />
                                    {openFilter && (
                                        <ClusterFilter
                                            onFilterChange={setCurrentFilter}
                                            onSortingChange={setCurrentSorting}
                                        />
                                    )}
                                </Box>
                            )}
                            {selectedTab == 1 && (
                                <Box sx={styles.flex}>
                                    <CoPilotList />
                                    {openFilter && (
                                        <ClusterFilter
                                            onFilterChange={setCurrentFilter}
                                            onSortingChange={setCurrentSorting}
                                        />
                                    )}
                                </Box>
                            )}
                            {/* {viewType === ViewType.VIEW_MODE_GRID && ( */}

                            {/* cluster chart component that is commented out */}
                            {/* <Box sx={styles.flexContainer}>
                                <Box sx={styles.halfContainer}>
                                    {selectedCluster != null && (
                                        <ClusterCharts
                                            isAdmin={currentUser?.role === UserRole.ADMIN}
                                            cluster={selectedCluster}
                                            user={selectedClusterUser}
                                        />
                                    )}
                                </Box>
                                {currentUser?.role === UserRole.ADMIN && (
                                    <Box sx={styles.halfContainer}>
                                        <ClusterUsers cluster={selectedCluster} onSelectUser={onSelectClusterUser} />
                                    </Box>
                                )}
                            </Box> */}
                            {/* )} */}
                        </Box>
                    </Box>
                    {/* {viewType === ViewType.VIEW_MODE_LIST && (
                        <Box sx={styles.flexContainer}>
                            <Box sx={styles.halfContainer}>
                                <ClusterCharts 
                                    isAdmin={currentUser?.role === UserRole.ADMIN} 
                                    cluster={selectedCluster} 
                                    user={selectedClusterUser} 
                                />
                            </Box>
                            {currentUser?.role === UserRole.ADMIN && (
                                <Box sx={styles.halfContainer}>
                                    <ClusterUsers
                                        cluster={selectedCluster}
                                        onSelectUser={onSelectClusterUser}
                                    />
                                </Box>
                            )}
                        </Box>
                    )} */}
                </Box>
            </Box>
            {
                openPaymentModal && (
                    <DynamicModel open={openPaymentModal} setOpen={setOpenPaymentModal}>
                        <PaymentStatusModal setOpen={setOpenPaymentModal} paymentDetails={paymentDetailsData} />
                    </DynamicModel>
                )
            }
        </Box>
    );
};

export default Dashboard;
