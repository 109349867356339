import { useAuth0 } from "@auth0/auth0-react";
import { CurrentUserSelector, setCurrentUser } from "app/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
// import { useAuth } from "services/auth";
import { currentUserHasUserRole } from "utils/auth.utils";

type PropsType = {
    children: any;
};

// `RequireAdminAuth` is the function to protect any routes that need to be Authentication Protected
// 1. children -> Represents all the children that are going to be Authentication Protected
export default function RequireUserAuth({ children }: PropsType) {
    const dispatch = useDispatch();
    const { user } = useAuth0();
    // This is ued to navigate user to the route which they were present on before being kicked out because of
    // Authentication failure
    const location = useLocation();

    // We make sure to check if the pathname is not "/". This is to ensure that the user is not directed to the path HostURL but
    // instead directed to /user
    const currentLocation = location.pathname !== "/" ? location.pathname : "/user";

    // If the user is not Authenticated, we send them back to the '/' route
    if (!user) {
        return null;
    }
    
    const reduxUser = useSelector(CurrentUserSelector);
    const roles = reduxUser?.roles;
    //const roles = user[`${window.location.origin}/roles`];

    if (!currentUserHasUserRole(roles)) {
        return <Navigate to="/" state={{ path: currentLocation }} />;
    }
    return children;
}
