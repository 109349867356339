import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    boxShadow: 15,
    padding: "15px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
};

const DynamicModel = ({ open, setOpen, title, children }: any) => {
    return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    {/* Close button */}
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon style={{ color: "#94A8B0" }} fontSize="small" />
                        </IconButton>
                    </Box>

                    {/* Model Title */}
                    {title && (
                        <Box style={{ display: "flex", justifyContent: "center" }}>
                            <Typography
                                id="keep-mounted-modal-title"
                                variant="h6"
                                component="h2"
                                fontWeight={500}
                                fontSize={18}
                                style={{ textAlign: "center", color: "#111111" }}
                            >
                                {title}
                            </Typography>
                        </Box>
                    )}

                    {/* Model Body */}
                    <Box>{children}</Box>
                </Box>
            </Modal>
        </div>
    );
};

export default DynamicModel;
