import { useAuth0 } from "@auth0/auth0-react";
import CircularLoadingProgress from "components/CircularProgress";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
const LoginButton = ({ variant, color, hide, orgId = undefined }: any) => {
    const { loginWithRedirect } = useAuth0();
    const [searchParams] = useSearchParams();
    const invitation = searchParams.get("invitation");
    const invitation_organization = searchParams.get("organization");

    useEffect(() => {
        console.log("inside useffect", orgId);
        if (orgId != undefined) {
            if (invitation && invitation_organization) {
                loginWithRedirect({
                    redirectUri: `${window.location.origin}/dashboard`,
                    invitation: invitation,
                    organization: invitation_organization,
                });
            } else {
                if (orgId?.length) {
                    loginWithRedirect({
                        redirectUri: `${window.location.origin}/dashboard`,
                        organization: orgId,
                    });
                } else {
                    loginWithRedirect({
                        redirectUri: `${window.location.origin}/dashboard`,
                    });
                }
            }
        }
    }, [orgId]);

    return <>{hide && <CircularLoadingProgress />}</>;
};

export default LoginButton;
