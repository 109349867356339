// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton } from "@mui/material";
import DeleteLightIcon from "assets/icons/delete-light.svg";
import { useAdminAssignedClusters, useAssignedOrganization, useFetchOrganizations } from "hooks/VSortsClusterData";
import { Search, TableComponent } from "pages/adminV2/Components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PropsType as ButtonType } from "../Components/Button";
import styles from "./../FilterBar/styles";
import DeleteOrganizationModal from "./DeleteOrganizationModal";
// import Search, { PropsType as SearchType } from "../Search";

type PropsType = {
    buttons: ButtonType[] | [];
    search: SearchType;
};

export type ItemType = {
    id: number;
    name: string;
    display_name: string;
};

const AssignOrganization = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = parseInt(searchParams.get("id"));
    const [clusterRows, setClusterRowa] = useState<any>([{}]);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [organizations, setOrganizations] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    // const [filteredOrganizations, setFilteredOrganizations] = useState(organizations);
    const [unassignedOrganizations, setUnassignedOrganizations] = useState([]);
    const [assignData, setAssignData] = useState({});
    const [selectedOrganizations, setSelectedOrganizations] = useState({});
    const [action, setAction] = useState("");
    const navigate = useNavigate();
    const [originalClusterRows, setOriginalClusterRows] = useState([]);
    const [originalUnassignedOrganizations, setOriginalUnassignedOrganizations] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [containerType, setContainerType] = useState("");
    const [isButtonActive, setIsButtonActive] = useState(false);

    const location = useLocation();
    const { pathname } = location;
    const segments = pathname.split("/");
    const containerTypes = ["forms", "clusters", "copilots", "vsets"];
    const currentContainerType = segments.find((segment) => containerTypes.includes(segment)) || "Not Found";

    console.log("FORMS VALUE", currentContainerType);

    useEffect(() => {
        let value = "";
        switch (currentContainerType) {
            case "forms":
                value = "presurvey";
                break;
            case "clusters":
                value = "vsorts_cluster";
                break;
            case "copilots":
                value = "ai_copilots";
                break;
            case "vsets":
                value = "vsorts";
                break;
            default:
                value = "Not Found";
        }
        setContainerType(value);
    }, [currentContainerType]);

    // `useAdminAssignedClusters` is the query to fetch All Admin related VSorts Cluster
    const {
        isFetching,
        isLoading: isLoadingClusters,
        isError,
        data: clusters,
        error,
        refetch: refetchClusters,
    } = useAdminAssignedClusters({});

    const {
        data: organizationData,
        error: organizationError,
        isLoading: organizationLoading,
        isError: organizationIsError,
        refetch: refetchFunction,
    } = useFetchOrganizations({
        containerID: id,
        containerType: containerType,
        onSuccess: (data: any) => {
            setOriginalUnassignedOrganizations(data.unassignedOrganizations);
            setOrganizations(data.assignedOrganizations);
            setUnassignedOrganizations(data.unassignedOrganizations);
        },
        onError: (error: any) => {
            console.error("Error fetching organizations:", error);
        },
        isEnabled: containerType !== "",
    });

    const handleSearch = (searchKey) => {
        setSearchValue(searchKey);

        if (searchKey.trim() === "" || searchKey === null) {
            setUnassignedOrganizations(originalUnassignedOrganizations);
        } else {
            const filteredUnassignedOrganizations = unassignedOrganizations.filter(
                (org) => org.display_name && org.display_name.toLowerCase().includes(searchKey.toLowerCase())
            );

            setUnassignedOrganizations(filteredUnassignedOrganizations);
        }
    };

    useEffect(() => {
        const rows = organizations?.map((organization: any) => {
            console.log(organization);
            return {
                id: organization.id,
                Organization: organization.display_name,
                Actions: "",
            };
        });

        setOriginalClusterRows(rows);
        setClusterRowa(rows);
    }, [organizations]);

    const onSaveSuccess = (data: any) => {
        refetchFunction();
        setSearchValue("");
    };
    const onSaveError = (data: any) => {
        console.log(data);
    };

    const {
        mutate: callingAssignOrganization,
        isError: isPostError,
        reset: resetCallAICoPilot,
        isLoading: isPostLoading,
        error: postError,
    } = useAssignedOrganization({
        data: assignData,
        onSuccess: onSaveSuccess,
        onError: onSaveError,
    });

    const callAssignOrganization = async () => callingAssignOrganization();

    const handleSubmit = async () => {
        await callAssignOrganization();
        setIsButtonActive(false);
    };

    const handleDelete = async () => {
        setIsOpen(true);
        // await callDeleteOrganization();
    };

    const handleCreateCluster = () => {
        console.log("handleCreateCluster");
        setOpenCreateCluster(true);
    };

    const handleSetClusterRules = () => {
        console.log("handleSetClusterRules");
    };

    const handleRowDeleteAction = (row: object) => {
        console.log("handleRowDeleteAction", row);
        setSelectedRow(row);
        setOpenDeleteCluster(true);
    };

    const handleRowEditAction = (row: any) => {
        console.log(row);
        const id = row["id"];
        const title = row["Cluster name"];
        navigate(`/v2/admin/clusters/create?title=${title}&id=${id}&isEdit=true`);
    };

    const handleRowViewAction = (row: any) => {
        const id = row["id"];
        const title = row["Cluster name"];
        navigate(`/v2/admin/clusters/create?title=${title}&id=${id}&isView=true`);
    };

    const handleRowDeployAction = (row: any) => {
        setSelectedRow(row);
        setOpenDeployCluster(true);
    };

    const handleSelectedData = (selectedData: ItemType | null) => {
        if (selectedData !== null) {
            setAssignData({
                containerType: containerType,
                containerID: id,
                orgId: selectedData.id,
            });
            setClusterRowa(clusterRows);
            setIsButtonActive(true);
        } else {
            setAssignData({});
        }
    };

    // const search: SearchType = {
    //     value: "",
    //     placeholder: "Search by name",
    //     onSearch: handleSearch,
    // };

    const buttons: ButtonType[] = [];

    const rowActions: any = [];

    const headerActions: any = [
        {
            tooltip: "Action",
            icon: <img src={DeleteLightIcon} alt="delete" />,
            onClick: (row: any) => handleDelete(),
        },
    ];

    if (error) {
        return <h2>Error</h2>;
    }

    if (isLoadingClusters) {
        return <h2>Loading</h2>;
    }

    if (clusterRows == undefined || clusterRows.length == 0) {
        setClusterRowa([
            {
                id: 1,
                Organization: "",
                Actions: "",
            },
        ]);
    }

    console.log("ISBUTTONACTIVE", isButtonActive);

    return (
        <div>
            <div className="createVSetContainer">
                <p>Add an organization to this list to give access</p>
                <div className="createVSetContainer__selectCat"></div>

                <Box sx={styles.searchContainer} style={{ flex: buttons?.length == 1 ? "5" : "2" }}>
                    <Search
                        value={searchValue}
                        onSearch={handleSearch}
                        placeholder={"Search By Name"}
                        items={unassignedOrganizations}
                        setSelectedData={handleSelectedData}
                        isButtonActive={setIsButtonActive}
                    />
                </Box>

                <div className="createVSetContainer__insert">
                    <IconButton sx={{ backgroundColor: `${isButtonActive ? "#1D9DD3" : ""}` }} onClick={handleSubmit}>
                        <AddIcon sx={{ color: "#fff" }} />
                    </IconButton>
                </div>
            </div>
            <Box sx={{ mt: 20 }}>
                <TableComponent
                    hideColumns={["id"]}
                    rows={clusterRows}
                    rowActions={rowActions}
                    headerActions={headerActions}
                    selected={selected}
                    setSelected={setSelected}
                    action={action}
                />
            </Box>

            {isOpen && (
                <DeleteOrganizationModal
                    id={id}
                    currentContainerType={currentContainerType}
                    containerType={containerType}
                    open={isOpen}
                    setOpen={setIsOpen}
                    selected={selected}
                    refetchFunction={refetchFunction}
                />
            )}
        </div>
    );
};

export default AssignOrganization;
