import { useAuth0 } from "@auth0/auth0-react";
import { CurrentUserSelector, setCurrentUser as SetCurrentUserSlice } from "app/slices/UserSlice";
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";
import { useEffect } from "react";
import { QueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { request } from "utils/axios-utils";

type PropsType = {
    children: any;
};

const fetchUserProfile = () => {
    return request({ url: NetworkConstants.getProfile, method: APIMethodConstants.get });
};

// `RequireAuth` is the function to protect any routes that need to be Authentication Protected
// 1. children -> Represents all the children that are going to be Authentication Protected
export default function RequireAuth({ children }: PropsType) {
    const { user, isAuthenticated, error, logout } = useAuth0();
    const cookies = new Cookies();

    const dispatch = useDispatch();
    // This is ued to navigate user to the route which they were present on before being kicked out because of
    // Authentication failure
    const location = useLocation();

    const navigate = useNavigate();
    const currentLocation = location.pathname;

    const reduxUser = useSelector(CurrentUserSelector);

    //set user to redux if not present, do not update state here
    useEffect(() => {
        if (reduxUser) {
            return;
        }
        const getUserFromApi = async () => {
            const queryClient = new QueryClient();
            const profileData = await queryClient.fetchQuery(QueryKeyConstants.getProfile, fetchUserProfile);
            dispatch(SetCurrentUserSlice(profileData ? profileData : null));
        }
        getUserFromApi()
        
    }, [])

    // If the user is not Authenticated, we send them back to the '/' route
    if (!user) {
        // return <Navigate to="/login" state={{ path: currentLocation }} />;
        return null;
    }
    if (isAuthenticated) {
        return children;
    } else {
        return <Navigate to="/login" state={{ path: currentLocation }} />;
    }
}
